import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div id="Footer">
        <div className="container">
          <h1>Based in Austin, TX</h1>
        </div>
      </div>
    );
  }
}

export default Footer;
